import { useContext } from "react";
import "./reportPopup.css";
import { AppContext } from "../../Context/AppContext";

function ReportPopup() {
  const { setShowReportPopup  , reportPopupData} = useContext(AppContext);

  
  return (

     <div className="reportPopupWrapper">

    <div className="repPopupWrap">

      <div className="ReppupupNav">
        <p
          onClick={() => {setShowReportPopup(false) 
          }}
          className="icon cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M19.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V5C21.5 3.89543 20.6046 3 19.5 3Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5 9L9.5 15"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.5 9L15.5 15"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
      </div>

       <main className="allrepOD">
        {
            reportPopupData?.map((item ,index)=>(
              <div key={index} className="singReport">
              <h2>&#8226; {item?.tweet_text ? item?.tweet_text : item}</h2>
              <p>
                  <a href={item?.tweet_url} target="_blank" className="tweetUrl">
                      {item?.tweet_url}
                  </a>
              </p>
          </div>
            ))
        }
        
       </main>

    </div>

     </div>
  );
}
export default ReportPopup;
