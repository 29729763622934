import "./accountManagement.css";
import Calendar from "../Components/Common/Calender";
import { useContext, useEffect, useRef, useState } from "react";
import { endpoints } from "../services/api";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../Context/AppContext";
import { setAccessToken } from "../reducer/slices/authSlice";
import InfiniteScroll from "react-infinite-scroll-component";

function AccountManagement() {
  const {
    setShowDeletePopup,
    setDeleteId,
    allUser,
    loading,
    getAllUsers,
    setAllApiDetails,
    allApiDetail,
    accountAllData,
   textEncrpted
  } = useContext(AppContext);

  const { refreshToken, accessToken } = useSelector((state) => state.auth);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showTable, setShowTable] = useState(false);

  const dispatch = useDispatch();

  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // refresh token handler
  async function refreshTokenHandler(whichOne) {
    try {
      const response = await fetch(endpoints.REFRESH_TOKEN_API, {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },

        body: JSON.stringify({ refresh: refreshToken }),
      });

      const formattedResponse = await response.json();

      if (response.status === 200) {
        const { access } = formattedResponse;


        localStorage.setItem("autoAiaccessToken" , JSON.stringify(textEncrpted(access)));

        dispatch(setAccessToken(access));

        if (whichOne === "getAllUser") {
          getAllUsers(
            `?page_size=${allApiDetail?.page_size}&page=${allApiDetail?.page}&param=${allApiDetail?.params}&ordering=${allApiDetail?.ordering}`
          );
        }
      } 
    } catch (error) {
      console.log(error);
    }
  }

  // handle admin
  const handleAdmin = async (uid, isChecked, email) => {
    const toastId = toast.loading("Loadin...");

    try {
      if (isChecked) {
        const response = await fetch(endpoints.USER_UPDATE_API + `${uid}/`, {
          method: "PATCH",

          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ is_staff: true }),
        });

        if (response.status === 200) {
          getAllUsers(
            `?page_size=${allApiDetail?.page_size}&page=${allApiDetail?.page}&param=${allApiDetail?.params}&ordering=${allApiDetail?.ordering}`
          );
          toast.success(`${email} can use Previlages of Admin`);
        }
      } else {
        const response = await fetch(endpoints.USER_UPDATE_API + `${uid}/`, {
          method: "PATCH",

          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ is_staff: false }),
        });

        if (response.status === 200) {
          toast.success(` ${email} cannot use Previlages of Admin`);
          getAllUsers(
            `?page_size=${allApiDetail?.page_size}&page=${allApiDetail?.page}&param=${allApiDetail?.params}&ordering=${allApiDetail?.ordering}`
          );
        } else if (response.status === 401) {
          refreshTokenHandler("handleAdmin");
        } 
      }
    } catch (error) {
      console.log(error);
    }
    toast.dismiss(toastId);
  };

  // handle enable
  const handleEnable = async (uid, isChecked, email) => {
    const toastId = toast.loading("Loadin...");

    try {
      if (isChecked) {
        const response = await fetch(endpoints.USER_UPDATE_API + `${uid}/`, {
          method: "PATCH",

          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ is_approved: true }),
        });

        if (response.status === 200) {
          getAllUsers(
            `?page_size=${allApiDetail?.page_size}&page=${allApiDetail?.page}&param=${allApiDetail?.params}&ordering=${allApiDetail?.ordering}`
          );
          toast.success(`${email} is now approved `);
        } 
      } else {
        const response = await fetch(endpoints.USER_UPDATE_API + `${uid}/`, {
          method: "PATCH",

          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ is_approved: false }),
        });

        if (response.status === 200) {
          toast.success(` ${email} is not approved `);
          getAllUsers(
            `?page_size=${allApiDetail?.page_size}&page=${allApiDetail?.page}&param=${allApiDetail?.params}&ordering=${allApiDetail?.ordering}`
          );
        } else if (response.status === 401) {
          refreshTokenHandler("handleAdmin");
        } 
      }
    } catch (error) {
      console.log(error);
    }
    toast.dismiss(toastId);
  };

  useEffect(() => {
    if (screenWidth > 1000) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [accessToken]);

  useEffect(() => {
    getAllUsers(
      `?page_size=${allApiDetail?.page_size}&page=${allApiDetail?.page}&param=${allApiDetail?.params}&ordering=${allApiDetail?.ordering}`
    );
  }, [
    accessToken,
    allApiDetail?.ordering,
    allApiDetail?.show,
    allApiDetail?.params,
    allApiDetail?.page_size,
  ]);

  const orderingHandler = async (value) => {
    setAllApiDetails((prev) => ({
      ...prev,
      ordering: value,
    }));
  };

  const [hasMore  , setHasMore] = useState(true);

  const fetchData = ()=>{

     if(accountAllData?.count == allUser?.length){
      setHasMore(false);
     }
    else{
      setTimeout(()=>{
        const queryString = accountAllData?.next.split("?")[1];
        getAllUsers("?" + queryString , true);

      },[500])

    }
   
  }


  useEffect(()=>{

    if(accountAllData?.count === allUser?.length){
      setHasMore(false);
     }

  },[accountAllData]);


   useEffect(()=>{
     if(allApiDetail !== "All"){
      setHasMore(true);
     }
   },[allApiDetail])


  return (
    <div className="accManaRight ">
      
      <div className="accManaHead">
        <p className="head_title">ACCOUNT MANAGEMENT</p>
        <p className="subHead">Account </p>
      </div>

      <div className="accManaShow">
        <div className="showAll">
          <p>Show</p>
          <Calendar
            values={allApiDetail?.show}
            changeHandler={(e) => {
              if (e.target.value == "All") {
                setAllApiDetails((prev) => ({
                  ...prev,
                  page_size: "10",
                  show: "All",
                }));
              } else {
                setAllApiDetails((prev) => ({
                  ...prev,
                  page_size: e.target.value,
                  show: e.target.value,
                }));
              }
            }}
            data={["All", "10", "20"]}
            options={["All", "10", "20"]}
            width={` ${
              screenWidth > 550
                ? "w-[182px] h-[40px] text-[18px]"
                : "w-[200px] h-[40px] text-[18px]"
            }`}
          />
        </div>

        <div className="search">
          <input
            type="text"
            value={allApiDetail?.params}
            onChange={(e) =>
              setAllApiDetails((prev) => ({
                ...prev,
                params: e.target.value,
              }))
            }
            placeholder="Search"
          />
        </div>
      </div>



      {showTable ? (
        
        <div class="table_wrapper">
         
          <table className="accManaData ">
            <thead className="tableHeading">
              <tr>
                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "date_joined") {
                      orderingHandler("-date_joined");
                    } else {
                      orderingHandler("date_joined");
                    }
                  }}
                  scope="col"
                  className="singleRow textDeco cursor-pointer"
                >
                  Date Created
                </th>

                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "email") {
                      orderingHandler("-email");
                    } else {
                      orderingHandler("email");
                    }
                  }}
                  scope="col"
                  className="singleRow2 textDeco cursor-pointer"
                >
                  Email
                </th>

                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "is_approved") {
                      orderingHandler("-is_approved");
                    } else {
                      orderingHandler("is_approved");
                    }
                  }}
                  scope="col"
                  className="secondRow textDeco cursor-pointer"
                >
                  Enable
                </th>

                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "is_staff") {
                      orderingHandler("-is_staff");
                    } else {
                      orderingHandler("is_staff");
                    }
                  }}
                  scope="col"
                  className="thirdRow textDeco cursor-pointer"
                >
                  Admin
                </th>
              </tr>
            </thead>

            <tbody>
              {allUser?.length > 0 ? (
                <>
               {

               
                allUser.map((data ,index) => (
                  <tr   className="headAns" key={data?.uid}>

                    <th scope="row" className={`singleTh`}>
                      {formatDate(data?.date_joined)}
                    </th>

                    <td className="singleTh2 text-black">{data?.email}</td>

                    <td className="singleTh3">
                      <input
                        type="checkbox"
                        checked={data?.is_approved}
                        onChange={(e) =>
                          handleEnable(data?.uid, e.target.checked, data?.email)
                        }
                      />
                    </td>

                    <td className="singleTh4">
                      <input
                        type="checkbox"
                        checked={data?.is_staff}
                        onChange={(e) => {
                          handleAdmin(data?.uid, e.target.checked, data?.email);
                        }}
                      />
                    </td>

                    <td className="singleTh5 oveflow-hidden">
                      <p
                        onClick={() => {
                          setShowDeletePopup(true);
                          setDeleteId(data?.uid);
                        }}
                        className="oveflow-hidden cursor-pointer"
                      >
                        delete
                      </p>
                    </td>
                  </tr>


                ))

                      }
                      {
                        allApiDetail?.show === "All" && 
              <InfiniteScroll dataLength={allApiDetail?.page_size} next={fetchData} hasMore={hasMore} loader={<h2>Loading...</h2>}>

</InfiniteScroll>
                      }

                </>

              ) : loading ? (
                <span>Loading...</span>
              ) : (
                <span>No users Exist </span>
              )}
            </tbody>


          </table>

        </div>
      ) : (
        
        <div class="relative smallWidthTable pl-5 overflow-x-auto ">
          <table class="w-[98%] mx-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

            <thead class="text-xs textDeco ">
              <tr>
                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "date_joined") {
                      orderingHandler("-date_joined");
                    } else {
                      orderingHandler("date_joined");
                    }
                  }}
                  scope="col"
                  class="px-2  py-3 "
                >
                  Date Created
                </th>

                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "email") {
                      orderingHandler("-email");
                    } else {
                      orderingHandler("email");
                    }
                  }}
                  scope="col"
                  class="px-6 py-3"
                >
                  Email
                </th>

                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "is_approved") {
                      orderingHandler("-is_approved");
                    } else {
                      orderingHandler("is_approved");
                    }
                  }}
                  scope="col"
                  class="px-6 py-3"
                >
                  Enable
                </th>

                <th
                  onClick={() => {
                    if (allApiDetail?.ordering === "is_staff") {
                      orderingHandler("-is_staff");
                    } else {
                      orderingHandler("is_staff");
                    }
                  }}
                  scope="col"
                  class="px-6 py-3"
                >
                  Admin
                </th>
                <th scope="col" class="px-6  "></th>
              </tr>
            </thead>

            <tbody >
              {allUser.length > 0 ? (
                <>
              
              {
                allUser.map((data, index) => (
                  <tr key={index} class="textDeco2 text-black ">
                    <td className="px-3 py-4 font-medium  whitespace-nowrap ">
                      {formatDate(data?.date_joined)}
                    </td>

                    <td className="px-6 py-4">{data.email}</td>
                    <td className="px-6 py-4 singleTh3">
                      <input
                        type="checkbox"
                        className="w-[19px] ml-4 h-[19px]"
                        checked={data?.is_approved}
                        onChange={(e) =>
                          handleEnable(data?.uid, e.target.checked, data?.email)
                        }
                      />
                    </td>
                    <td className="px-6 py-4 singleTh3">
                      <input
                        type="checkbox"
                        className="w-[19px] ml-4 h-[19px]"
                        checked={data?.is_staff}
                        onChange={(e) => {
                          handleAdmin(data?.uid, e.target.checked, data?.email);
                        }}
                      />
                    </td>

                    <td
                      onClick={() => {
                        setShowDeletePopup(true);
                        setDeleteId(data?.uid);
                      }}
                      className="px-6 translate-x-[-30px] py-4 cursor-pointer overflow-x-hidden "
                    >
                      Delete
                    </td>
                  </tr>
                ))
                    }

                <InfiniteScroll dataLength={allApiDetail?.page_size} next={fetchData} hasMore={hasMore} loader={<h2>Loading...</h2>}>

                </InfiniteScroll>

                </>
              ) : loading ? (
                <span>Loading....</span>
              ) : (
                <span>NO user exist </span>
              )}
            </tbody>

          </table>
        </div>
      )}

      {(allApiDetail?.show !== "All" && (accountAllData?.previous !== null || accountAllData?.next !== null)) && (

        <div  className="buttonRightLeft ">
          <button className={``}
            onClick={() => {
              if (accountAllData?.previous !== null) {
                const queryString = accountAllData?.previous.split("?")[1];
                getAllUsers("?" + queryString);
                setAllApiDetails((prev) => ({
                  ...prev,
                  page: allApiDetail?.page - 1,
                }));
              }
            }}
            disabled={accountAllData?.previous === null} >
            <span>Prev</span>
          </button>

          <div>{allApiDetail?.page}</div>

          <button
            onClick={() => {
              if (accountAllData?.next !== null) {
                const queryString = accountAllData?.next.split("?")[1];
                getAllUsers("?" + queryString);
                setAllApiDetails((prev) => ({
                  ...prev,
                  page: allApiDetail?.page + 1,
                }));
              }
            }}
            disabled={accountAllData?.next === null}
          >
            <span>Next</span>
          </button>
        </div>
      )}
    </div>
  );
}
export default AccountManagement;
