export const sideBarItems = [
    {
        title:"ACCOUNT FEEDS" , 
    },
    {
        title:"TOPICS" , 
    },
    {
        title:"GENERATE" , 
    },
    {
        title:"AUTOMATE" , 
    },
    {
        title:"SCHEDULED" , 
    },

]

export const superUser = [
    {
        title:"PROMPT MANAGEMENT" , 
    },
    {
        title:"ACCOUNT MANAGEMENT",
    }
]
export const staffUser = [
    {
        title:"ACCOUNT FEEDS" , 
    },
    {
        title:"TOPICS" , 
    },
    {
        title:"GENERATE" , 
    },
    {
        title:"AUTOMATE" , 
    },
    {
        title:"SCHEDULED" , 
    },
    {
        title:"REPORT LOGS"
    }
    
]