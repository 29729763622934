import { useContext, useEffect, useState } from "react";
import "./reportLogs.css";
import { AppContext } from "../Context/AppContext";
import { endpoints } from "../services/api";
import { useSelector } from "react-redux";

function ReportLogs() {
  const { setShowReportPopup, setReportPopupData , generateNewAccessToken } = useContext(AppContext);

  const { accessToken } = useSelector((state) => state.auth);

  const [currentShow, setCurrentShow] = useState(null);
  const [currentShow2, setCurrentShow2] = useState(null);

  const [commented_tweet, setCommented_tweet] = useState([]);
  const [tweets_generate, setTweets_generate] = useState([]);

  const [loading, setLoding] = useState(false);

  const fetchReportHandler = async (newaccess=false) => {
    setLoding(true);
    try {
      const response = await fetch(endpoints.REPORT_API, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
        },
      });

      const formattedResponse = await response.json();

      if (response.status === 200) {
        setCommented_tweet(formattedResponse?.commented_tweets);
        setTweets_generate(formattedResponse?.tweets_generate);
      }
      else if(response.status === 401){
        //  401CODE
    const newaccess =   await generateNewAccessToken();
    if(newaccess){
      await fetchReportHandler(newaccess);
    }
         
       }
    } catch (error) {
      console.log(error);
    }
    setLoding(false);
  };

  const seeListHandler = async (data , newaccess=false) => {
    if (!Array.isArray(data)) {
      setReportPopupData([data]);
      setShowReportPopup(true);
      return;
    }

    try {
      const response = await fetch(endpoints.GET_TWEET_REPORT_API, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
        },
        body: JSON.stringify({ tweets_id: data }),
      });

      const formattedResponse = await response.json();

      if (response.status >= 200 && response.status <= 299) {
        setReportPopupData(formattedResponse);
        setShowReportPopup(true);
      }
      else if(response.status === 401){
        //  401CODE
      const newaccess =  await generateNewAccessToken();
      if(newaccess){
        await seeListHandler(data , newaccess);
      }
         
       }
    } catch (error) {
      console.log(error);
    }
  };

  const seeGenerateHandler = async (data) => {
    if (Array.isArray(data)) {
      setReportPopupData([...data]);
      setShowReportPopup(true);
      return;
    } else {
      const { tweet_text, tweet_url } = data;
      setReportPopupData([{ tweet_text, tweet_url }]);
      setShowReportPopup(true);
    }
  };

  useEffect(() => {
    fetchReportHandler();
  }, []);

  return (
    <div className="reportGenRight">
      <div className="reportGenContent  sm:px-0 px-3">
        <div className="reportGenHead">
          <p>REPORT LOGS</p>
        </div>

        <main className="mainReeport">
          {loading ? (
            <div className="reportBlock">
              <span class="loader"></span>
            </div>
          ) : (
            <>
              {/* first  */}

              <div className="firstSec">
                {tweets_generate?.length > 0 && (
                  <>
                    <h1 className="geneComTtile"> Scheduled Tweets</h1>
                    <div className="reportBlock">
                      {tweets_generate?.map((tw, index) => (
                        <div key={index} className="alltweetGen">
                          <div
                            onClick={() => {
                              setCurrentShow(index);
                            }}
                            className={`sinRport`}
                          >
                            <span>&#8226; {tw?.tweets_generated}</span>
                          </div>

                          {currentShow === index && (
                            <article className="reBlArti">
                              <div className="rebAFir">
                                <p>{tw?.admin_ins}</p>
                              </div>

                              {tw?.user_ins !== null && tw?.user_ins !== "" && (
                                <div className="sinReb">
                                  <h2>--- USER INSTRUCTIONS ---</h2>
                                  <p>Write context of the tweet should be:</p>
                                  <p
                                    onClick={async () => {
                                      seeListHandler(tw?.user_ins);
                                    }}
                                    className="cursor-pointer font-[700]"
                                  >
                                    [ click to see the list]
                                  </p>
                                </div>
                              )}

                              {tw?.prev_tweets != null &&
                                tw?.prev_tweets?.length > 0 && (
                                  <div className="sinReb">
                                    <h2>--- MY PREVIOUS TWEETS ---</h2>
                                    <p
                                      onClick={() => {
                                        seeListHandler(tw?.prev_tweets);
                                      }}
                                      className="cursor-pointer font-[700]"
                                    >
                                      [ click to see the list]
                                    </p>
                                  </div>
                                )}

                              {tw?.account_tweets != null &&
                                tw?.account_tweets?.length > 0 && (
                                  <div className="sinReb">
                                    <h2>--- INFLUENCER TWEETS ---</h2>
                                    <p
                                      onClick={() => {
                                        seeListHandler(tw?.account_tweets);
                                      }}
                                      className="cursor-pointer font-[700]"
                                    >
                                      [ click to see the list]
                                    </p>
                                  </div>
                                )}

                              {tw?.topic_tweets != null &&
                                tw?.topic_tweets?.length > 0 && (
                                  <div className="sinReb">
                                    <h2>--- TRENDING TWEETS ---</h2>
                                    <p
                                      onClick={() => {
                                        seeListHandler(tw?.topic_tweets);
                                      }}
                                      className="cursor-pointer font-[700]"
                                    >
                                      [ click to see the list]
                                    </p>
                                  </div>
                                )}
                            </article>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              {/* /second tweet  */}

              <div>
                {commented_tweet?.length  > 0 && (
                  <>
                    <h1 className="geneComTtile"> Generated Comments</h1>
                    <div className="reportBlock">
                      {commented_tweet?.map((tw, index) => (
                        <div key={index} className="alltweetGen">
                          <div
                            onClick={() => {
                              setCurrentShow2(index);
                            }}
                            className={`sinRport`}
                          >
                            <span>&#8226; {tw?.reply}</span>
                          </div>

                          {currentShow2 === index && (
                            <article className="reBlArti">
                              <div className="rebAFir">
                                <p>{tw?.admin_ins}</p>
                              </div>

                              {tw?.self_tweet != null &&
                                tw?.self_tweet?.length > 0 && (
                                  <div className="sinReb">
                                    <h2>--- SELF TWEETS ---</h2>
                                    <p
                                      onClick={() => {
                                        seeGenerateHandler(tw?.self_tweet);
                                      }}
                                      className="cursor-pointer font-[700]"
                                    >
                                      [ click to see the list]
                                    </p>
                                  </div>
                                )}

                              {tw?.posted_tweet != null && (
                                <div className="sinReb">
                                  <h2>--- POSTED TWEETS ---</h2>
                                  <p
                                    onClick={() => {
                                      seeGenerateHandler(tw?.posted_tweet);
                                    }}
                                    className="cursor-pointer font-[700]"
                                  >
                                    [ click to see the list]
                                  </p>
                                </div>
                              )}

                              {tw?.topic_tweets != null &&
                                tw?.topic_tweets?.length > 0 && (
                                  <div className="sinReb">
                                    <h2>--- TRENDING TWEETS ---</h2>
                                    <p
                                      onClick={() => {
                                        seeGenerateHandler(tw?.topic_tweets);
                                      }}
                                      className="cursor-pointer font-[700]"
                                    >
                                      [ click to see the list]
                                    </p>
                                  </div>
                                )}
                            </article>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </main>
      </div>
    </div>
  );
}

export default ReportLogs;
