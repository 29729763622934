import { useContext, useState,  } from "react";
import logo from "../../assets/Navbar Brand.png"
import "./header.css"
import { IoReorderThree } from "react-icons/io5";
import { AppContext } from "../../Context/AppContext";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { endpoints } from "../../services/api";
import { setAccessToken, setIsApproved, setIsTwitterAuth, setRefreshToken, setStaff, setSuperUser } from "../../reducer/slices/authSlice";




function Header({showBtn=true , showSmBtn=true  , RequestRecieveLogout=false}){

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {refreshToken} = useSelector((state)=>state.auth);

    const {setShowSidebar , showSidebar ,setCount , logoutCondition} = useContext(AppContext);

    const [isButtonDisabled , setisButtonDisabled] = useState(false);

      // logout handler 
  const logoutHandler = async()=>{

    setisButtonDisabled(true);
   
       const toastId = toast.loading("Loading...");
       try{
   
         const response = await fetch(endpoints.LOGOUT_API , {
   
           method:"POST",
           headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${refreshToken}`,
             },
             body: JSON.stringify({refresh : refreshToken}),
           })
                  
         toast.success("Successfuly logout");
         dispatch(setSuperUser(null))
         dispatch(setStaff(null));
         dispatch(setIsApproved(null));
         dispatch(setIsTwitterAuth(null));
         dispatch(setRefreshToken(null));
         dispatch(setAccessToken(null));
         sessionStorage.removeItem("autoAiCurrentPage");

        
         
         logoutCondition();
   
         navigate("/");
         
 
       } catch(error){
         console.log(error);
   
       }
   
       setisButtonDisabled(false);
   
       toast.dismiss(toastId);
     }
   

    return (

        <div className="headerWrapper">

        <div className={`HeaderContainer ${showBtn && "setHomPage"} `}>

            <img onClick={()=>navigate("/")} src={logo} alt="" className="cursor-pointer" />

            {
 showBtn ?(

    <div className="btnWrap">
     <button onClick={()=>navigate("/login")}><span>LOGIN</span></button>
     <button onClick={()=>navigate("/request-access")}><span>TRY FOR FREE</span></button>
    </div>
    
     
     ):(
    showSmBtn &&  

    <>

<div className="logoutBtn">

     <button disabled={isButtonDisabled} onClick={()=>{
        logoutHandler();
     }}><span>LOGOUT</span></button>
    </div>
  
    <span onClick={()=>
        {
            setShowSidebar((prev)=>!prev)
        setCount(1);
          
        }}>{
        showSidebar ?(
            <ImCross  className={` sidebarIcon  text-[20px]`} />
            ):(
                 <IoReorderThree  className={`sidebarIcon text-[40px]`} />
        )
    }
    </span>
    </>
 )
            }

            {
RequestRecieveLogout && 
<div className="logoutBtnReci">

<button disabled={isButtonDisabled} onClick={()=>{
   logoutHandler();
}}><span>LOGOUT</span></button>
</div>

            }

        </div>

        
        </div>
    )
}

export default Header;