import { useNavigate } from "react-router-dom";
import hoPaImg from "../../assets/image 11.png";
import "./home.css";


function HeroSection() {

  const navigate = useNavigate();

  return (
    <div className="hoPaWrap">

      <div className="hoPaContain">

        {/* left part  */}
        <div className="hoPa_content ">

          <h2>
            {/* Automated <br /> Tweeting, Replying <br /> and Liking */}
            Automated Tweeting, Replying  and Liking
          </h2>

          <div className="hoPaPara">
            <p className="para1">It's as if you had a real life social media assistant.</p>
            <p className="para">  Ditch the time sink of writing your own tweets, thinking up replies and Liking other peoples content.  Let AI do it for you.{" "}
            </p>
          </div>

          <div className=" btnTextWrap">
            <button onClick={()=>navigate("/request-access")} className="tryBtn">TRY FOR FREE</button>

            <p className="noCreditText mt-1">No credit card required</p>
          </div>

        </div>

        {/* right part  */}
        <div className="hoPa_img">
          <img src={hoPaImg} alt="" />
        </div>

      </div>
      
    </div>
  );
}

export default HeroSection;
