import { useEffect } from "react";
import { useNavigate } from "react-router-dom";



function PageNotFound({dashboard = false , connection = false ,isTwitter=false ,goHome = false ,Request ,goConnection=false}){

    const navigate = useNavigate();

    useEffect(()=>{
        
           if(dashboard || isTwitter){
            navigate("/dashboard");
           }
           else if(isTwitter){
            navigate("/dashboard");
           }
           else if(connection){
 navigate("/connection");
           }
    },[])

    useEffect(()=>{
           if(goHome){
            navigate("/")
           }
           else if(Request){
            navigate("/request-recieved")
           }
           else if(goConnection){
              navigate("/dashboard");
           }
            
        
    },[])



     
}

export default PageNotFound;