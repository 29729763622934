import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage";
import RequestAccess from "./Pages/RequestAccess";
import Login from "./Pages/Login";
import RequestRecieve from "./Pages/RequestRecieve";
import ResetPassword from "./Pages/ResetPassword";
import { useSelector } from "react-redux";
import Dashboard from "./Pages/Dashboard";
import PageNotFound from "./Pages/PageNotFound";
import PasswordResetConfirm from "./Pages/PasswordResetConfirm";
import AuthorizePage from "./Pages/AuthorizePage";

function App() {
  const {
    refreshToken,
    is_superUser,
    is_staff,
    is_approved,
    is_twitter_authorized,
  } = useSelector((state) => state.auth);

  return (
    <section className="w-full h-full">
      <Routes>

        <Route path="/" element={<HomePage />} />
        
        {refreshToken !== null ? (
          <>
            {is_superUser === true ? (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/*" element={<PageNotFound dashboard={true} />} />
              </>
            ) : 


            is_approved === false ? (
              <>
                <Route path="/" element={<HomePage />} />
                <Route path="/request-recieved" element={<RequestRecieve />} />
                <Route path="/*" element={<PageNotFound Request={true} />} />
              </>
            ) : (
              
                is_twitter_authorized === true ? (
                  <>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route  path="/*" element={<PageNotFound isTwitter={true} />}  />
                </>
                ) : (
                  <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/authorize" element={<AuthorizePage />} />
                  <Route path="/*" element={<PageNotFound goConnection={true} />} />
                  </>
                )
            )}
          </>
        ) : (
          <>
            <Route path="/change-password" element={<ResetPassword />} />

            <Route path="/login" element={<Login />} />

            <Route path="/request-access" element={<RequestAccess />} />

            <Route path="/reset-password" element={<PasswordResetConfirm />} />

            <Route path="/*" element={<PageNotFound goHome={true} />} />


          </>
        )}
      </Routes>
    </section>
  );
}

export default App;
