import "./requestAccess.css";
import Header from "../Components/Common/Header";
import { useContext, useEffect, useState } from "react";
import { endpoints } from "../services/api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  setAccessToken,
  setIsApproved,
  setIsTwitterAuth,
  setRefreshToken,
  setStaff,
  setSuperUser,
} from "../reducer/slices/authSlice";
import { useDispatch } from "react-redux";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { AppContext } from "../Context/AppContext";

function RequestAccess() {
  const { covertBooleanDecrpte3d , booleanEncrpt ,   textEncrpted ,textdecrpted} = useContext(AppContext);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isButtonDisabled, setisButtonDisabled] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const changeHandler = (e) => {
    e.preventDefault();

    const { value, name } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [errorMessage, setErrorMessage] = useState('');


  const changeHandler2 = (e) => {

    e.preventDefault();

    const { value, name } = e.target;

    if (value.length < 12) {
        setErrorMessage('Password is too short (less than 12 characters)');
      }
       else {
        const containsOnlyNumbers = /^\d+$/.test(value);
        if (containsOnlyNumbers) {
          setErrorMessage('Password must contain at least one letter');
        } else {
          setErrorMessage('');
        }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  useEffect(()=>{

    if(formData.password === ""){
        setErrorMessage("");
    }

  },[formData.password])


  // another
  const checkUserHandler = async (access, refresh) => {
    try {
      const response = await fetch(endpoints.CHECK_USERDETAILS_API, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      const formattedResponse = await response.json();

      if (response.status === 200) {
        const { is_staff, is_superuser, is_approved, is_twitter_authorized } =
          formattedResponse;

        dispatch(setSuperUser(is_superuser));
        dispatch(setStaff(is_staff));
        dispatch(setIsTwitterAuth(is_twitter_authorized));
        dispatch(setIsApproved(is_approved));
        dispatch(setAccessToken(access));
        dispatch(setRefreshToken(refresh));

        localStorage.setItem("autoAiRefreshToken" , JSON.stringify(textEncrpted(refresh)));
        localStorage.setItem("autoAiaccessToken" , JSON.stringify(textEncrpted(access)));
        localStorage.setItem("autoAiis_approved" , JSON.stringify(booleanEncrpt(is_approved)));
        localStorage.setItem("autoAiis_twitter_authorized" , JSON.stringify(booleanEncrpt(is_twitter_authorized)));
        localStorage.setItem("autoAiis_staff" , JSON.stringify(booleanEncrpt(is_staff)));
        localStorage.setItem("autoAiis_superuser" , JSON.stringify(booleanEncrpt(is_superuser)));


        if (is_staff || is_superuser) {
          navigate("/dashboard");
        } else if (!is_approved) {
          navigate("/request-recieved");
        } else if (!is_twitter_authorized) {
          navigate("/connection");
        } else {
          navigate("/dashboard");
        }

        toast.success("Successfully Signup");
      } else if (response.status === 401) {
        const {  detail, messages } = formattedResponse;

        if (messages?.message) {
          toast.error(messages?.message);
        } else if (detail) {
          toast.error(detail);
        } else {
          toast.error("Something went wrong , please try again");
        }
      } else {
        toast.error("Something went wrong , please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // another
  const submitHandler = async (e) => {
    setisButtonDisabled(true);
    const toastId = toast.loading("Loading...");

    e.preventDefault();

    try {
      const response = await fetch(endpoints.SIGNUP_API, {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },

        body: JSON.stringify(formData),
      });

      const formattedResponse = await response.json();

      if (response.status >= 200 && response.status <= 299) {
        const { access, refresh } = formattedResponse;
        checkUserHandler(access, refresh);
      } else if (response.status === 401) {
        const { detail } = formattedResponse;
        toast.error(detail);
      } else if (response.status === 400) {
        const { email, password } = formattedResponse;

        if (email) {
          toast.error(email);
        } else if (password) {
          toast.error(password);
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }

    setisButtonDisabled(false);

    toast.dismiss(toastId);
  };

  return (
    <div className="reqAccWrap">
      <Header showBtn={false} showSmBtn={false} />

      <div className="reqAccContain overflow-x-hidden">
        <div className="req_HP">
          <h2 className="req_head">Request Access</h2>

          <p className="req_para">Create your account to request access</p>
        </div>

        <form onSubmit={submitHandler}>
          <div className="emailCon">
            <label htmlFor="">Email</label>
            <input
              required
              name="email"
              value={formData.email}
              onChange={changeHandler}
              type="email"
              placeholder="john@doe.com"
            />
          </div>

          <div className="emailCon">
            <label htmlFor="">Password</label>

            <div className="inputPassWrap">

              <input
                required
                value={formData.password}
                name="password"
                onChange={changeHandler2}
                type={showPassword?"text":"password"}
                placeholder="At least 12 characters with numbers and special characters"
              />

              <span>
                {!showPassword ? (
                  <FaEyeSlash
                    className="showPassword"
                    onClick={() => setShowPassword(true)}
                  />
                ) : (
                  <FaEye
                    className="showPassword"
                    onClick={() => setShowPassword(false)}
                  />
                )}
              </span>
            </div>


          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </div>

          <button disabled={isButtonDisabled} type="submit" className="sendBtn">
            <span>Send Request</span>
          </button>

          <div className="alreadyWrap">
            <p onClick={() => navigate("/login")}>Already Registered? Login</p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RequestAccess;
