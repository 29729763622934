import React from 'react';
import './calender.css'; 

const Calendar = ({data , width , accountManagement=true , options = false , name , changeHandler , values , disabled , isAutomate=false}) => {
  return (
    <div className="custom-select-container">
      <select disabled={disabled} value={values} name={name} onChange={changeHandler} className={` ${accountManagement?('custom-select'):('custom_select2')} -z-20 ${width}  ${isAutomate && 'changeBorder'}`}>

        {
          !options ?
          <option value={``}>{data}</option>:(
            options?.map((data , index)=>(
           <option key={index} value={data} >{data}</option>
            ))
          )
        }
       
      </select>

      <div className="custom-arrow-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 21 15" fill="none">
  <path d="M3.83889 0.462036L0 4.30093L10.237 14.538L20.4741 4.30093L16.6352 0.462036L10.237 6.86019L3.83889 0.462036Z" fill="#F34A4A"/>
</svg>
      </div>
      
    </div>
  );
};

export default Calendar;
