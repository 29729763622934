import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from './Context/AppContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import rootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast';


const store = configureStore({
  reducer:rootReducer,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store} >
  <BrowserRouter>
  <AppContextProvider>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <App />
    <Toaster />
    </LocalizationProvider>
  </AppContextProvider>
  </BrowserRouter>
  </Provider>

);
