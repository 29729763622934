import "./topics.css";
import { useContext, useEffect, useState } from "react";
import { NumericFormat } from 'react-number-format';
import { endpoints } from "../services/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import box from "../assets/alpha-x-box.png"
import { AppContext } from "../Context/AppContext";


function Topics() {

  const [accData , setAccData] = useState([]);

  const {accessToken } = useSelector((state)=>state.auth);
  const {generateNewAccessToken} = useContext(AppContext);

  const [topic , setTopic] = useState("");

  const [inputValue, setInputValue] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [inputValue3, setInputValue3] = useState('');

 const postTopicHandler = async(newaccess=false)=>{


  if(topic === ""){
    return ;
  }
  try{

    const topics = topic.split(',').map(item => item.trim()).filter(item => item !== '');


    const response = await fetch(endpoints.POST_INFLUENCER_API, {
      method: "POST",
      headers: {

        "content-type": "application/json",
        Authorization: `Bearer ${newaccess?newaccess:accessToken}`,

      },
      body: JSON.stringify({topics}),
    });


    if(response.status >=200 && response.status<299){
      const formatedResponse = await response.json();

      setAccData(formatedResponse?.topics);
     setTopic("");
    }
    else if(response.status === 400){
      toast.error("Maximum number of Topic exceed");
      setTopic("");
    }
    else if(response.status === 401){
      //  401CODE
     const newaccess = await generateNewAccessToken();
     if(newaccess){
       await postTopicHandler(newaccess);
      }
       
     }
  } catch(error){
    console.log(error);
  }

 }

 const getAllTopics = async(newaccess=false)=>{
  try{

      const response = await fetch(endpoints.POST_INFLUENCER_API, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
      },
    });

    if(response.status === 200){
   const formated = await response.json();
  setAccData(formated?.topics);
  setInputValue(formated?.top_nb_likes);
  setInputValue2(formated?.top_nb_comments);
  setInputValue3(formated?.top_nb_views);
 }
 else if(response.status === 401){
      //  401CODE
     const newaccess = await generateNewAccessToken();
     if(newaccess){
       await getAllTopics(newaccess);
      }
       
     }
  } catch(error){
    console.log(error);
  }
 }

 const deleteTopic = async(id , newaccess=false)=>{

  try{

    const formData = new FormData();
    formData.append('topics', id);

     const response = await fetch(endpoints.POST_INFLUENCER_API, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
      },
       body: formData,

    });


    if(response.status >=200 && response.status<299){
      getAllTopics();
    }
   else if(response.status === 401){
      //  401CODE
    const newaccess =  await generateNewAccessToken();
    if(newaccess){
      await deleteTopic(id , newaccess);
    }
       
     }

  } catch(error){
    console.log(error);
  }

 }


 useEffect(()=>{
 getAllTopics();
 },[])


//  for more thatn
const moreThanHandler = async(value , value2 , value3 ,newaccess=false)=>{
  try{

    let formattedNumber = value.toString().replace(/,/g, '');
    let formattedNumber2 = value2.toString().replace(/,/g, '');
    let formattedNumber3 = value3.toString().replace(/,/g, '');

     if(formattedNumber === ""){
      formattedNumber = 0;
     }
     if(formattedNumber2 === ""){
      formattedNumber2 = 0;
     }
     if(formattedNumber3 === ""){
      formattedNumber3 = 0;
     }


    const response = await fetch(endpoints.POST_INFLUENCER_API, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${newaccess?newaccess:accessToken}`,

      },
       body: JSON.stringify({"top_nb_likes":formattedNumber , "top_nb_comments":formattedNumber2 , "top_nb_views":formattedNumber3}),

    });

    if(response.status >=200 && response.status<299){
      const formatedResponse = await response.json();
    }
    else if(response.status === 401){
      //  401CODE
    const newaccess =  await generateNewAccessToken();
    if(newaccess){
      await moreThanHandler(value , value2 , value3 , newaccess);
    }
       
     }


  } catch(error){
    console.log(error);
  }
}



  return (

        <div className="topicRightCont">

          <div className="topicContent overflow-x-hidden">

            <div className="headTitl">
              <p>TOPICS</p>
            </div>

            <div className="headPara">
              <p>
                Add the topics of interest that you’d like to generate tweets
                about
              </p>
            </div>

            <div className="topicMainBlock">
              {/* first row  */}
              <div className="topicCol1">
                {/* input field */}
                <div className="inputContTopic">
                  <input
                   onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      postTopicHandler();
                    }
                  }}
                  onChange={(e)=>setTopic(e.target.value)}
                    type="text" value={topic}
                    placeholder="Add topics of interest (comma separated)"
                  />

                  <span onClick={postTopicHandler} className="cursor_pointer_tpic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                      className=""
                    >
                      <g clip-path="url(#clip0_3110_185)">
                        <path
                          d="M13 0.390625C6.31055 0.390625 0.890625 5.81055 0.890625 12.5C0.890625 19.1895 6.31055 24.6094 13 24.6094C19.6895 24.6094 25.1094 19.1895 25.1094 12.5C25.1094 5.81055 19.6895 0.390625 13 0.390625ZM20.0312 13.8672C20.0312 14.1895 19.7676 14.4531 19.4453 14.4531H14.9531V18.9453C14.9531 19.2676 14.6895 19.5312 14.3672 19.5312H11.6328C11.3105 19.5312 11.0469 19.2676 11.0469 18.9453V14.4531H6.55469C6.23242 14.4531 5.96875 14.1895 5.96875 13.8672V11.1328C5.96875 10.8105 6.23242 10.5469 6.55469 10.5469H11.0469V6.05469C11.0469 5.73242 11.3105 5.46875 11.6328 5.46875H14.3672C14.6895 5.46875 14.9531 5.73242 14.9531 6.05469V10.5469H19.4453C19.7676 10.5469 20.0312 10.8105 20.0312 11.1328V13.8672Z"
                          fill="#F34A4A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3110_185">
                          <rect
                            width="25"
                            height="25"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>

                </div>


                <div className="max10T">
                  <p>Max 10</p>
                </div>
              </div>

              {/* secons row  */}

              <div className="topicCol2 space-y-[2px]">
                {accData.map((acc) => (
                  <div key={acc?.id} className="singleTopic">
                    <p className="TopicId">{acc?.topic_name}</p>
                    <span onClick={()=>deleteTopic(acc?.id)}>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <path
                          d="M7.25 5.54167L8.75 9.5L7.25 13.4583H8.75L9.5 11.4792L10.25 13.4583H11.75L10.25 9.5L11.75 5.54167H10.25L9.5 7.52083L8.75 5.54167H7.25ZM4.25 2.375H14.75C15.1478 2.375 15.5294 2.54181 15.8107 2.83875C16.092 3.13568 16.25 3.53841 16.25 3.95833V15.0417C16.25 15.4616 16.092 15.8643 15.8107 16.1613C15.5294 16.4582 15.1478 16.625 14.75 16.625H4.25C3.85218 16.625 3.47064 16.4582 3.18934 16.1613C2.90804 15.8643 2.75 15.4616 2.75 15.0417V3.95833C2.75 3.53841 2.90804 3.13568 3.18934 2.83875C3.47064 2.54181 3.85218 2.375 4.25 2.375Z"
                          fill="#333333"
                        />
                      </svg> */}
                      <img src={box} alt="" className="cursor-pointer" />
                    </span>
                  </div>
                ))}
              </div>

              {/* fourth  */}
              <div className="TopicfetchTwetCont">
                <div className="titl">
                  <p>Fetch Only Topic Tweets With:</p>
                </div>

                <div className="ftbox1">
                  <p className="topicComTex">More than </p>

                  <div className="TopicomBox">
                    <NumericFormat allowNegative={false}  thousandSeparator="," placeholder="50" value={inputValue}  onChange={(e)=>
                      {
                        setInputValue(e.target.value);
                        moreThanHandler(e.target.value , inputValue2 ,inputValue3)
                      }
                       } />
                  </div>

                  <span className="topicComTex">Likes</span>
                </div>
                {/* box2 */}
                <div className="ftbox1">
                  <p className="topicComTex">More than </p>

                  <div className="TopicomBox">
                    <NumericFormat allowNegative={false}  thousandSeparator=","  placeholder="20" value={inputValue2}  onChange={(e)=>
                      {
                        setInputValue2(e.target.value);
                        moreThanHandler( inputValue,e.target.value ,inputValue3)
                    }} />
                  </div>

                  <span className="topicComTex">Comments</span>
                </div>
                {/* box3 */}
                <div className="ftbox1">
                  <p className="topicComTex">More than </p>

                  <div className="TopicomBox">
                    <NumericFormat allowNegative={false}  thousandSeparator="," placeholder="10,000" value={inputValue3}  onChange={(e)=>{
                      setInputValue3(e.target.value);
                      moreThanHandler( inputValue,inputValue2 ,e.target.value)
                    }} />
                  </div>

                  <span className="topicComTex">Views</span>
                </div>
              </div>
            </div>
          </div>

        </div>

  );
}

export default Topics;
