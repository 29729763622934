import { useContext } from "react";
import "./deletePopup.css";
import { AppContext } from "../../Context/AppContext";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { endpoints } from "../../services/api";


function DeletePopup() {
  const { setShowDeletePopup ,setDeleteId , deleteId , getAllUsers ,allApiDetail ,generateNewAccessToken} = useContext(AppContext);

  const {accessToken} = useSelector((state)=>state.auth);

    const deleteUser = async(newaccess=false)=>{

      const toastId = toast.loading("Loadin...");
  
      try{
  
        const response = await fetch(endpoints.DELETE_USER_API + `${deleteId}/`, {
          method: "DELETE",
  
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
          },
          body: JSON.stringify({uid:deleteId}),
        });
  
  
        if (response.status >= 200 && response.status <= 299 ) {
          toast.success(`Successfuly deleted`);
          getAllUsers(
            `?page_size=${allApiDetail?.page_size}&page=${allApiDetail?.page}&param=${allApiDetail?.params}&ordering=${allApiDetail?.ordering}`
          );
          setShowDeletePopup(false);
          setDeleteId(null)
        
        }
        else if(response.status === 401){
          //  401CODE
        const newaccess =  await generateNewAccessToken();
        if(newaccess){
          await deleteUser(newaccess);
        }
         }
  
      } catch(error){
        console.log(error);
      }
  
      toast.dismiss(toastId);
    }
 
  return (
     <div className="deletePopupWrapper">

    
    <div className="delPopupWrap">
      <div className="DelpupupNav">
        <p
          onClick={() => {setShowDeletePopup(false) 
          setDeleteId(null)}}
          className="icon cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M19.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V5C21.5 3.89543 20.6046 3 19.5 3Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5 9L9.5 15"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.5 9L15.5 15"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
      </div>

     <h2 className="deleteSure">Are you sure you want to delete ?</h2>


      <div className="reBtn">
        <button onClick={deleteUser} className="reshbtn">
          <span>Delete</span>
        </button>

        <button onClick={()=>{setDeleteId(null)
         setShowDeletePopup(false);
        }} className="scheduleBtn">
          <span>Cancel</span>
        </button>
      </div>
    </div>

    </div>
  );
}
export default DeletePopup;
