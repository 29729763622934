import { useContext, useEffect, useState } from "react";

import "./adminGenerate.css";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import { endpoints } from "../services/api";
import toast from "react-hot-toast";
import { AppContext } from "../Context/AppContext";

function GeneratePage() {
  const { is_superUser , accessToken  } = useSelector((state) => state.auth);

  const {generateNewAccessToken} = useContext(AppContext);

  const [is_Admin, set_is_Admin] = useState(false);

  useEffect(() => {
    if (is_superUser) {
      set_is_Admin(true);
    } else {
      set_is_Admin(null);
    }
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue3, setInputValue3] = useState("");
  const [inputValue4, setInputValue4] = useState("");
  const [inputValue8, setInputValue8] = useState("");
  const [inputValue9, setInputValue9] = useState("");

  const [loading , setLoading] = useState(false);

  
  const hitAPIHandler = async(max1 , max2 , max3 , max4 ,max5 , max6 ,popup=false  , newaccess=false)=>{
    try{

       if(max1 === ""){
        max1 = null;
       }
       if(max2 === ""){
        max2 = 0;
       }
       if(max3 === ""){
        max3 = 0;
       }
       if(max4 === ""){
        max4 = 0;
       }
       if(max5 == ""){
        max5 = 0;
       }
       if(max6 == ""){
        max6 = 0;
       }
  
      const requestBody = {
        prev_tweet: value === "My Previous Tweets",
        account_tweet: value2 === "Influencer tweets",
        topic_tweet: value3 === "Trending tweets",
        posted_tweet: value4 === "POSTED TWEET" , 
        prev_replies: value5 === "My Previous Replies" , 
        reply_prev_tweets : value6 === "My Previous Tweets" , 
        generate_prompt : value7 === "Generate Prompt",
      
  
            };
  
      const response = await fetch(endpoints.ADMIN_PROMPT_API, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
        },
        body: JSON.stringify({"nb_prev_replies_fetched": max4 ,"nb_prev_tweets_fetched":max3 , "nb_tweets_acc": max1 , "nb_tweets_top":max2 ,"scraping_threshold_duration":max6, "reply_limitation":max5 , "prev_tweet":requestBody.prev_tweet ,"account_tweet":requestBody.account_tweet ,"topic_tweet": requestBody.topic_tweet , "posted_tweet" : requestBody.posted_tweet , "prev_replies": requestBody.prev_replies , "reply_prev_tweets":requestBody.reply_prev_tweets ,"generate_prompt": requestBody.generate_prompt, "tweets_prompt": content , "reply_prompt": content2 , "posted_tweet":requestBody.posted_tweet }) 
      });
  
        if(response.status === 200 && popup){
          toast.success("Successfully Updated");
        }
        else if(response.status === 401){
          //  401CODE
       const newaccess =  await generateNewAccessToken();
       if(newaccess){
         await hitAPIHandler(max1 , max2 , max3 , max4 ,max5 , max6 ,popup , newaccess);
        }
           
         }
  
    } catch(error){
      console.log(error);
    }
  }

  const handleInputChange = (e) => {
  
     let newInputValue = e.target.value;

      if(parseInt(newInputValue) > 20){
        toast.error("Value Cannot more than 20");
        setInputValue('20');
        return ;
      }
      
    setInputValue(newInputValue);

    hitAPIHandler(newInputValue, inputValue2, inputValue3, inputValue4 , inputValue8 , `00:${inputValue9}:00`);

  };

  const handleInputChange2 = (e) => {
    let newInputValue = e.target.value;
     if(parseInt(newInputValue) > 20){
      toast.error("Value Cannot more than 20");

      setInputValue2('20');
      return ;
     }
    setInputValue2(newInputValue);
    hitAPIHandler(inputValue , newInputValue , inputValue3 , inputValue4 , inputValue8 , `00:${inputValue9}:00`);
  };
  
  const handleInputChange3 = (e) => {
    let newInputValue = e.target.value;
     
    if(parseInt(newInputValue) > 10){
      toast.error("Value Cannot more than 10");

      setInputValue3('10');
      return ;
    }
    
    setInputValue3(newInputValue);
    hitAPIHandler(inputValue , inputValue2 , newInputValue , inputValue4 , inputValue8 , `00:${inputValue9}:00`);
  };

  useEffect(() => {
    let timeoutId;

    const delayedFunction = () => {
     if(parseInt(inputValue8 ) < 1 || inputValue8 === ""){
      setInputValue8("1");
      hitAPIHandler(inputValue , inputValue2 , inputValue3 , inputValue4 , '1' , `00:${inputValue9}:00`);

     }
    };

    clearTimeout(timeoutId);

    // Set a new timeout
    timeoutId = setTimeout(delayedFunction, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue8]);

  const handleInputChange8 = (e) => {
    let newInputValue = e.target.value;
     
    if(parseInt(newInputValue) < 1 || newInputValue  === ""){

      toast.error("Value cannot less than 1");
      setInputValue8(newInputValue);
      return ;
    }
    
    setInputValue8(newInputValue);
    hitAPIHandler(inputValue , inputValue2 , inputValue3 , inputValue4 , newInputValue , `00:${inputValue9}:00`);
  };

  useEffect(() => {
    let timeoutId;

    const delayedFunction = () => {
     if(parseInt(inputValue9 ) <15){
      setInputValue9("15");
      hitAPIHandler(inputValue , inputValue2 , inputValue3 , inputValue4 , inputValue8 , `00:15:00`);

     }
    };

    clearTimeout(timeoutId);

    // Set a new timeout
    timeoutId = setTimeout(delayedFunction, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue9]);
  
  const handleInputChange9 = (e) => {
    let newInputValue  = e.target.value;
    
    if(parseInt(newInputValue) < 15 || newInputValue === ""){
      
      toast.error("Value cannot less than 15");
      setInputValue9(newInputValue);

     }
     else {
       setInputValue9(newInputValue);
       hitAPIHandler(inputValue , inputValue2 , inputValue3 , inputValue4 , inputValue8 , `00:${newInputValue}:00`);
      }
  };
  

  const [value, setValue] = useState("My Previous Tweets");
  const [value2, setValue2] = useState("Influencer tweets");
  const [value3, setValue3] = useState("Trending tweets");
  const [value4, setValue4] = useState("POSTED TWEET");
  const [value5, setValue5] = useState("My Previous Replies");
  const [value6, setValue6] = useState("My Previous Tweets");
  const [value7, setValue7] = useState("Generate Prompt");

  const [suggestions, setSuggestions] = useState([]);
  const [suggestions2, setSuggestions2] = useState([]);
  const [suggestions3, setSuggestions3] = useState([]);
  const [suggestions4, setSuggestions4] = useState([]);
  const [suggestions5, setSuggestions5] = useState([]);
  const [suggestions6, setSuggestions6] = useState([]);
  const [suggestions7, setSuggestions7] = useState([]);

  const getSuggestions = (value) => {

    const tweetsSuggestions = ["My Previous tweets"];

    return tweetsSuggestions;
  };
  const getSuggestions2 = (value) => {
    const tweetsSuggestions = ["Influencer tweets"];

    return tweetsSuggestions;
  };
  const getSuggestions3 = (value) => {

    const tweetsSuggestions = ["Trending tweets"];

    return tweetsSuggestions;
  };
  const getSuggestions4 = (value) => {
    
    const tweetsSuggestions = ["POSTED TWEET"];

    return tweetsSuggestions;
  };
  const getSuggestions5 = (value) => {
    const tweetsSuggestions = ["My Previous Replies"];

    return tweetsSuggestions;
  };
  const getSuggestions6 = (value) => {
 
    const tweetsSuggestions = ["My Previous Tweets"];

    return tweetsSuggestions;
  };
  const getSuggestions7 = (value) => {
 
    const tweetsSuggestions = ["Generate Prompt"];

    return tweetsSuggestions;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };
  const onSuggestionsFetchRequested2 = ({ value }) => {
    setSuggestions2(getSuggestions2(value));
  };
  const onSuggestionsFetchRequested3 = ({ value }) => {
    setSuggestions3(getSuggestions3(value));
  };
  const onSuggestionsFetchRequested4 = ({ value }) => {
    setSuggestions4(getSuggestions4(value));
  };
  const onSuggestionsFetchRequested5 = ({ value }) => {
    setSuggestions5(getSuggestions5(value));
  };
  const onSuggestionsFetchRequested6 = ({ value }) => {
    setSuggestions6(getSuggestions6(value));
  };
  const onSuggestionsFetchRequested7 = ({ value }) => {
    setSuggestions7(getSuggestions7(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onSuggestionsClearRequested2 = () => {
    setSuggestions2([]);
  };
  const onSuggestionsClearRequested3 = () => {
    setSuggestions3([]);
  };
  const onSuggestionsClearRequested4 = () => {
    setSuggestions4([]);
  };
  const onSuggestionsClearRequested5 = () => {
    setSuggestions5([]);
  };
  const onSuggestionsClearRequested6 = () => {
    setSuggestions6([]);
  };
  const onSuggestionsClearRequested7 = () => {
    setSuggestions7([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setValue(suggestion);
  };
  const onSuggestionSelected2 = (event, { suggestion }) => {
    setValue2(suggestion);
  };
  const onSuggestionSelected3 = (event, { suggestion }) => {
    setValue3(suggestion);
  };
  const onSuggestionSelected4 = (event, { suggestion }) => {
    setValue4(suggestion);
  };
  const onSuggestionSelected5 = (event, { suggestion }) => {
    setValue5(suggestion);
  };
  const onSuggestionSelected6 = (event, { suggestion }) => {
    setValue6(suggestion);
  };
  const onSuggestionSelected7 = (event, { suggestion }) => {
    setValue7(suggestion);
  };

  const onChange = (event, { newValue }) => {

    setFirstCall(true);

       if(!newValue?.includes(value)){
             setValue("");
       }
       else {
         setValue(newValue);
        }

  };

  const onChange2 = (event, { newValue }) => {
    setFirstCall(true);
    if(!newValue?.includes(value2)){
      setValue2("");
}
else {
  setValue2(newValue);
 }
  };

  const onChange3 = (event, { newValue }) => {
    setFirstCall(true);
    if(!newValue?.includes(value3)){
      setValue3("");
}
else {
  setValue3(newValue);
 }
  };

  const onChange4 = (event, { newValue }) => {
    setFirstCall(true);
    if(!newValue?.includes(value4)){
      setValue4("");
}
else {
  setValue4(newValue);
 }
  };

  const onChange5 = (event, { newValue }) => {
    setFirstCall(true);
    if(!newValue?.includes(value5)){
      setValue5("");
}
else {
  setValue5(newValue);
 }
  };

  const onChange6 = (event, { newValue }) => {
    setFirstCall(true);
    if(!newValue?.includes(value6)){
      setValue6("");
}
else {
  setValue6(newValue);
 }
  };

  const onChange7 = (event, { newValue }) => {
    setFirstCall(true);
    if(!newValue?.includes(value7)){
      setValue7("");
}
else {
  setValue7(newValue);
 }
  };

  const inputProps = {
    placeholder: "Type something",
    value: value,
    onChange: onChange,
  };

  const inputProps2 = {
    placeholder: "Type something",
    value: value2,
    onChange: onChange2,
  };

  const inputProps3 = {
    placeholder: "Type something",
    value: value3,
    onChange: onChange3,
  };

  const inputProps4 = {
    placeholder: "Type something",
    value: value4,
    onChange: onChange4,
  };
  
  const inputProps5 = {
    placeholder: "Type something",
    value: value5,
    onChange: onChange5,
  };

  const inputProps6 = {
    placeholder: "Type something",
    value: value6,
    onChange: onChange6,
  };

  const inputProps7 = {
    placeholder: "Type something",
    value: value7,
    onChange: onChange7,
  };

  const [content, setContent] = useState(``);

  const [content2, setContent2] = useState(` `);


  const getApiData = async(newaccess=false)=>{
    setLoading(true);
    try{

      const response = await fetch(endpoints.ADMIN_PROMPT_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
        },
      });

       const formatedResponse = await response.json();
       if(response.status === 200){
       const {tweets_prompt , reply_prompt , nb_prev_replies_fetched , nb_prev_tweets_fetched , nb_tweets_acc , nb_tweets_top , reply_limitation , scraping_threshold_duration} = formatedResponse;

        setContent(tweets_prompt);
        setContent2(reply_prompt);
        setInputValue(nb_tweets_acc);
        setInputValue2(nb_tweets_top);
        setInputValue3(nb_prev_tweets_fetched);
        setInputValue4(nb_prev_replies_fetched);
        setInputValue8(reply_limitation);

        var parts = scraping_threshold_duration.split(":");
        let desiredValue = 0;
         if(parts[0] !== "00"){
               let num = parseInt(parts[0]);
               desiredValue = num + num*60;
         }

         if(parts[1] !== "00"){
            desiredValue += parseInt(parts[1]);
         }

setInputValue9(`${desiredValue}`);

       }
       else if(response.status === 401){
        //  401CODE
      const newaccess =  await generateNewAccessToken();
      if(newaccess){
        await getApiData(newaccess);
      }
         
       }

    } catch(error){
      console.log(error);
    }

    setLoading(false);
  }

useEffect(()=>{
  getApiData();
  
},[]);

const [firstCall , setFirstCall] = useState(false);

  useEffect(()=>{
    if(firstCall){
      hitAPIHandler(inputValue , inputValue2 , inputValue3 , inputValue4 );
    }
  },[value , value2 ,value3 , value4 , value5 , value6 , value7 ]);



  return (
    <div className="adminGenRight">
      <div className="adminGenContent  sm:px-0 px-3">

        <div className="adminGenHead">
          <p>ADMIN</p>
        </div>

        {
          loading ? 
           <div className="loadingWrap">

          <span class="loader"></span>
           </div>
          :
          <>
        

        <div className="adMaxTw2">
          <p className="adtitl">Max Tweets fetched per Influencer:</p>
          <div className="adbox">
            <input
              placeholder="5"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="adMaxTw2">
          <p className="adtitl">Max Tweets fetched per Topic:</p>
          <div className="adbox">
            <input
              placeholder="8"
              value={inputValue2}
              onChange={handleInputChange2}
            />
          </div>
        </div>

        <div className="adMaxTw2">
          <p className="adtitl">Max # of My Previous Tweets fetched :</p>
          <div className="adbox">
            <input
              type="number"
              placeholder="8"
              value={inputValue3}
              onChange={handleInputChange3}
            />
          </div>
        </div>

        <div className="adMaxTw2">
          <p className="adtitl">scraping frequency (in minutes) :</p>
          <div className="adbox">
            <input
              type="number"
              placeholder="15"
              value={inputValue9}
              onChange={handleInputChange9}
            />
          </div>
        </div>

        <div className="adMaxTw2">
          <p className="adtitl">Number of replies per user :</p>
          <div className="adbox">
            <input
              type="number"
              placeholder="8"
              value={inputValue8}
              onChange={handleInputChange8}
            />
          </div>
        </div>

       

        {/* first one  */}
        <div className="adPromtGene">

          <div className="title">
            <p>Prompt for GENERATING new content</p>
          </div>

          <div className="adMainCont">
            <div className="generateText">
              <div className="placeholderWork">
   

              <div className="eidtablePara1 first"  contentEditable={true}
               onBlur={(e) => setContent(e.target.innerText)}
              onChange={(e)=>setContent(e.target.value)}
               >
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </div>

                <br />
                <p className="first">--- USER INSTRUCTIONS ---</p>

                <p className="first">Write context of the tweet should be:</p>
                <p className="first firstAgain">
                  {" "}
                  [
                  <Autosuggest
                    suggestions={suggestions7}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested7}
                    onSuggestionsClearRequested={onSuggestionsClearRequested7}
                    onSuggestionSelected={onSuggestionSelected7}
                    getSuggestionValue={(suggestion7) => suggestion7}
                    renderSuggestion={(suggestion7) => <div>{suggestion7}</div>}
                    inputProps={inputProps7}
                  />
                  ]
                </p>

                <br />

                <p className="first">--- MY PREVIOUS TWEETS ---</p>
                <p className="first firstAgain">
                  {" "}
                  [
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    getSuggestionValue={(suggestion) => suggestion}
                    renderSuggestion={(suggestion) => <div>{suggestion}</div>}
                    inputProps={inputProps}
                  />
                  ]
                </p>

                <br />

                <p className="first">--- INFLUENCER TWEETS ---</p>
                <p className="first firstAgain">
                  {" "}
                  [
                  <Autosuggest
                    suggestions={suggestions2}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested2}
                    onSuggestionsClearRequested={onSuggestionsClearRequested2}
                    onSuggestionSelected={onSuggestionSelected2}
                    getSuggestionValue={(suggestion2) => suggestion2}
                    renderSuggestion={(suggestion2) => <div>{suggestion2}</div>}
                    inputProps={inputProps2}
                  />
                  ]
                </p>

                <br />

                <p className="first">--- TRENDING TWEETS ---</p>
                <p className="first firstAgain">
                  {" "}
                  [
                  <Autosuggest
                    suggestions={suggestions3}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested3}
                    onSuggestionsClearRequested={onSuggestionsClearRequested3}
                    onSuggestionSelected={onSuggestionSelected3}
                    getSuggestionValue={(suggestion3) => suggestion3}
                    renderSuggestion={(suggestion3) => <div>{suggestion3}</div>}
                    inputProps={inputProps3}
                  />
                  ]
                </p>
              </div>
            </div>

            <button 
            onClick={()=>hitAPIHandler(inputValue ,inputValue2 ,inputValue3 , inputValue4, true)}
             className="updateBtn">
              <span>UPDATE</span>
            </button>
          </div>
        </div>

        {/* second promt  */}

        <div className="promptReply">
          <div className="title">
            <p>Prompt for REPLYING to COMMENTS</p>
          </div>

          <div className="replyContent">
            <div className="replygeneText">
              <div className="placeholderWork">
              <div className="eidtablePara1 first"  contentEditable={true} onBlur={(e) => setContent2(e.target.innerText)}>
      <span dangerouslySetInnerHTML={{ __html: content2 }} />
    </div>
                <br />
                <p className="first">--- POSTED TWEET ---</p>
                <p className="first firstAgain">
                  {" "}
                  [
                  <Autosuggest
                    suggestions={suggestions4}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested4}
                    onSuggestionsClearRequested={onSuggestionsClearRequested4}
                    onSuggestionSelected={onSuggestionSelected4}
                    getSuggestionValue={(suggestion4) => suggestion4}
                    renderSuggestion={(suggestion4) => <div>{suggestion4}</div>}
                    inputProps={inputProps4}
                  />
                  ]
                </p>
                <br />

                <p className="first">--- MY PREVIOUS REPLIES ---</p>
                <p className="first firstAgain">
                  {" "}
                  [
                  <Autosuggest
                    suggestions={suggestions5}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested5}
                    onSuggestionsClearRequested={onSuggestionsClearRequested5}
                    onSuggestionSelected={onSuggestionSelected5}
                    getSuggestionValue={(suggestion5) => suggestion5}
                    renderSuggestion={(suggestion5) => <div>{suggestion5}</div>}
                    inputProps={inputProps5}
                  />
                  ]
                </p>
                <br />
                <p className="first">--- MY PREVIOUS TWEETS ---</p>
                <p className="first firstAgain">
                  {" "}
                  [
                  <Autosuggest
                    suggestions={suggestions6}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested6}
                    onSuggestionsClearRequested={onSuggestionsClearRequested6}
                    onSuggestionSelected={onSuggestionSelected6}
                    getSuggestionValue={(suggestion6) => suggestion6}
                    renderSuggestion={(suggestion6) => <div>{suggestion6}</div>}
                    inputProps={inputProps6}
                  />
                  ]
                </p>
              </div>
            </div>

            <button 
            onClick={()=>hitAPIHandler(inputValue ,inputValue2 ,inputValue3 , inputValue4, true)} 
            className="updateBtn">
              <span>UPDATE</span>
            </button>
          </div>
        </div>

          
        </>
        }

      </div>
    </div>
  );
}

export default GeneratePage;
