import React, { useContext, useEffect, useState } from "react";
import "./genpopup.css";
import { AppContext } from "../../Context/AppContext";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import { endpoints } from "../../services/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);


function GenPopup() {
  const [isSchedule, setIsSchedule] = useState(false);

  const {
    setShowGenerPopup,
    generatePopupData,
    setGeneratePopupData,
    state,
    setState,
    setLoadingData,
    loadingData,
    allgenpopupdata,
    genLoading,
    genPopupText,
    setCurrentPage,
    generateNewAccessToken
  } = useContext(AppContext);

  const [value, setValue] = React.useState(dayjs(new Date()));
  const { accessToken } = useSelector((state) => state.auth);

  const handleDateTimeChange = (newValue) => {
    setValue(newValue);
  };

  const currentDateTime = dayjs(new Date());


  // this is ffor schedule
  const scheduleTweetHandler = async (newaccess=false) => {
    try {
      const { account_tweets, admin_ins, topic_tweets, user_ins, gpt_prompt } =
        allgenpopupdata;

      // let datetime = value.format("YYYY-MM-DDTHH:mm:ssZ");
      let datetime = dayjs(value).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");


      const response = await fetch(endpoints.SCHEDULE_TWEET_API, {
        method: "POST",

        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
        },

        body: JSON.stringify({
          account_tweets,
          admin_ins,
          topic_tweets,
          user_ins,
          tweets_generated: generatePopupData,
          datetime,
          gpt_prompt,
        }),
      });

      // const form = await response.json();

      if (response.status === 200) {
        toast.success("Successfully Scheduled");
        setCurrentPage("SCHEDULED");
        sessionStorage.setItem("autoAiCurrentPage", "SCHEDULED");
        setShowGenerPopup(false);
      } 
      else if(response.status === 401){
        //  401CODE
      const newaccess =  await generateNewAccessToken();
      if(newaccess){

        await scheduleTweetHandler(newaccess);
        
      }
       }
      else {
        toast.error("Please try again");
      }
    } catch (error) {
      console.log(error);
    }

    setIsSchedule(false);
  };

  const handleTextareaChange = (event, index) => {
    const updatedPopupData = [...generatePopupData];
    updatedPopupData[index] = event.target.value;
    setGeneratePopupData(updatedPopupData);
  };

  return (
    <div className="genpopupWrap">
      <div className="GenpupupNav adjustflex">
        <h3> { genLoading ? "Generating Post...":"AI Generated Post"} </h3>

        <p
          onClick={() => {
            setShowGenerPopup(false);
            setState(null);
          }}
          className="icon cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M19.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V5C21.5 3.89543 20.6046 3 19.5 3Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5 9L9.5 15"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.5 9L15.5 15"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
      </div>

      {genLoading ? (
        <div className="flex flex-col items-center gap-5 my-auto">
          <span class="loader mx-auto"></span>
          <h2 className=" font-[500] ">{genPopupText}</h2>
        </div>
      ) : (
        <>
          <div className="allDatapopup">
            {generatePopupData?.map((item, index) => (
              <div className="GenfirstTextPop">
                <textarea
                  cols="30"
                  rows="10"
                  key={index}
                  value={item}
                  onChange={(event) => handleTextareaChange(event, index)}
                />
              </div>
            ))}
          </div>

          <div className="GenpopupSchedule">
            <p className="genText">Schedule (GMT+5:30)</p>

            <div className="dateTimePicker">
              <MobileDateTimePicker
                label="10:30AM Friday 14th June 2024"
                format="h:mm A dddd DD  MMMM YYYY"
                ampm={true}
                className="dateTimePick"
                value={value}
                minDateTime={currentDateTime}
                onChange={handleDateTimeChange}
              />
            </div>
          </div>

          <div className="re-genBtn">
            <button
              onClick={async () => {
                if (state !== "running") {
                  await setGeneratePopupData([]);
                  setState("running");
                  setLoadingData((prev) => ({
                    ...prev,
                    loading4: true,
                  }));
                }
              }}
              className="genbtn"
            >
              {loadingData.loading4 === true ? (
                <span className="loading"></span>
              ) : (
                <span> RE-GENERATE</span>
              )}
            </button>

            <button
              onClick={() => {
                if (!isSchedule) {
                  scheduleTweetHandler();
                  setIsSchedule(true);
                }
              }}
              className="genscheduleBtn"
            >
              {isSchedule === true ? (
                <span className="loading"></span>
              ) : (
                <span> SCHEDULE</span>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
export default GenPopup;
