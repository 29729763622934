import "./resetPassword.css";
import Header from "../Components/Common/Header";
import { useEffect, useState } from "react";
import { endpoints } from "../services/api";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';


function ResetPassword() {

  const location = useLocation();
  const { state } = location;

  const [isButtonDisabled , setisButtonDisabled] = useState(false);

  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    email: "",
  });

  const changeHandler = (e) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // forgot password
  const submitHandler = async (e) => {

    const toastId = toast.loading("Loading...");

    setisButtonDisabled(true);
    e.preventDefault();

    if (formData.email === "") {
      return toast.error("Please Enter the email");
    }

    try {
      const response = await fetch(endpoints.FORGOT_PASSWORD_API, {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },

        body: JSON.stringify(formData),
      });

      const formattedResponse = await response.json();

      if (response?.status === 200) {
        toast.success(`Successfuly send mail to the mail ID ${formData.email}`);

        setFormData({
          email: "",
        });
      } else if (response.status === 400) {
        const { email } = formattedResponse;
        if (email) {
          toast.error(email);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setisButtonDisabled(false);

     toast.dismiss(toastId);

  };

  useEffect(()=>{
   if(state === null){
             navigate("/login");
   }
  },[])

  return (
    <div className="loginWrap">
      <Header showBtn={false} showSmBtn={false} />

      <div className="loginContain overflow-x-hidden">
        <div className="log_HP">
          <h2 className="log_head">Reset Password</h2>
        </div>

        <form onSubmit={submitHandler}>
          <div className="logemailCon">
            <label htmlFor="">Email</label>
            <input
              required
              name="email"
              value={formData.email}
              onChange={changeHandler}
              type="email"
              placeholder="john@doe.com"
            />
          </div>

          <button disabled={isButtonDisabled} type="submit" className="loginBtn">
            <span>Submit</span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
