import { useContext, useEffect, useState } from "react";
import "./generate.css";
import { AppContext } from "../Context/AppContext";
import { NumericFormat } from "react-number-format";
import { endpoints } from "../services/api";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";


function GeneratePage() {

  const {
    setCurrentPrompt,
    state,
    setState,
    setLoadingData,
    promptData , 
    setPromptData , 
    generateNewAccessToken
  } = useContext(AppContext);

  const { accessToken } = useSelector((state) => state.auth);

  const [loading , setLoading] = useState(false);

  const fetchAllPrompt = async (newaccess=false) => {
    setLoading(true);
    try {
      const response = await fetch(endpoints.SAVE_PROMPT_GENERATE_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
        },
      });

      const formResp = await response.json();

      if (response.status === 200) {
        setPromptData(formResp);
      }
      else if(response.status === 401){
        //  401CODE
      const newaccess =  await generateNewAccessToken();
      if(newaccess){
        await fetchAllPrompt(newaccess);
      }
         
       }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  
  const submitPromptChange = async (user_prompt , max_char ,thread_size , newaccess=false)=>{

    const filteredPrompts = user_prompt.filter(prompt => prompt !== '' && prompt !== undefined && prompt !== null && prompt !=="");
     
    if(filteredPrompts?.length===1 && filteredPrompts[0] === ''){
      return ;
    }

    if (max_char === null || Number.isNaN(max_char)) {
      return toast.error("Provide max_char");
  }
      
  if (thread_size === null || Number.isNaN(thread_size)) {
    return toast.error("Provide thread_size");
}
  
    try {
      const response = await fetch(endpoints.SAVE_PROMPT_GENERATE_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${newaccess?newaccess:accessToken}`,
        },
        body: JSON.stringify({
          max_char , 
          thread_size ,
 user_prompt: filteredPrompts
})
      });

       if(response.status === 401){
        //  401CODE
      const newaccess = await generateNewAccessToken();
      if(newaccess){
        await submitPromptChange(user_prompt , max_char ,thread_size , newaccess);
      }
         
       }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchAllPrompt();
  }, []);

  useEffect(()=>{

    if(promptData?.max_char > 280){
       setPromptData((prev)=>({
        ...prev ,
        max_char: 280
       }))
    }

  },[promptData?.max_char]);


  useEffect(()=>{

    if(promptData?.thread_size > 10){
       setPromptData((prev)=>({
        ...prev ,
        thread_size: 10
       }))
    }

  },[promptData?.thread_size])


  return (
    <div className="generateRight">
      <div className="geneContent overflow-x-hidden">
        
        <div className="gene_head">
          <p>GENERATE</p>
        </div>

        <div className="gene_Para ">
          <p>
            Provide a final tweak to the prompts used to generate the tweets{" "}
          </p>
        </div>

        <div className="genPara ">
          <p>
            “Based on the tweets from the influencers and the topics of interest
            provided as inputs, please write for me:”{" "}
          </p>
        </div>


 {
  loading ? 
  <span class="loader"></span>
  :

  
  <div className="geneMainblock">
          
  {/* first block */}
  <div className="genBlock">
    <div className="genInputB">
    <textarea
type="text"
// value={promptData?.user_prompt[0] || ''}
value={promptData?.user_prompt && promptData.user_prompt[0] ? promptData.user_prompt[0] : ''}
placeholder="Write Prompt Here"
onChange={async (e) => {
setPromptData((prev) => ({
...prev,
user_prompt: [
e.target.value,
prev?.user_prompt[1] || '',
prev?.user_prompt[2] || '',
],
}));

let user_prompt = [
e.target.value,
promptData?.user_prompt && promptData.user_prompt[1] ? promptData.user_prompt[1] : '',
promptData?.user_prompt && promptData.user_prompt[2] ? promptData.user_prompt[2] : '',
];

let max_char = promptData?.max_char;
let thread_size = promptData?.thread_size;

// for max char
const numericmax = max_char.toString(); 
const stringmaxWithoutCommas = numericmax.replace(/,/g, ''); 
const maxnumericValue = parseFloat(stringmaxWithoutCommas); 


// for thread
const numericThread = thread_size.toString(); 
const stringThreadWithoutCommas = numericThread.replace(/,/g, ''); 
const threadnumericValue = parseFloat(stringThreadWithoutCommas); 

submitPromptChange(user_prompt , maxnumericValue , threadnumericValue);

}}
></textarea>

    </div>

    <button
      onClick={async () => {
        if(promptData.user_prompt[0] === "" || promptData.user_prompt[0] === undefined ){
          toast.error("Please write Prompt");
        }
       else if (state !== "running") {
          await setCurrentPrompt({
            user_prompt: promptData?.user_prompt[0],
            type: '1'
          });
          setLoadingData((prev) => ({
            ...prev,
            loading1: true,
          }));
          setState("running");
        }
      }}
      className="genBtn"
    >
        <span> GENERATE</span>
    </button>
  </div>

  {/* second block */}
  <div className="genBlock">
    <div className="genInputB">
      <textarea
        type="text"
        // value={promptData?.user_prompt[1] || ''}
        value={promptData?.user_prompt && promptData.user_prompt[1] ? promptData.user_prompt[1] : ''}
        placeholder="Write Prompt Here"
        onChange={(e)=>{
          setPromptData((prev) => ({
            ...prev,
            user_prompt: [
              prev?.user_prompt[0] || '',
        e.target.value,
        prev?.user_prompt[2] || '',
            ],
          }));

          let user_prompt = [
            promptData?.user_prompt[0] || '',
            e.target.value,
            promptData?.user_prompt[2] || '',
          ];

          let max_char = promptData?.max_char;
          let thread_size = promptData?.thread_size;

             // for max char
const numericmax = max_char.toString(); 
const stringmaxWithoutCommas = numericmax.replace(/,/g, ''); 
const maxnumericValue = parseFloat(stringmaxWithoutCommas); 


// for thread
const numericThread = thread_size.toString(); 
const stringThreadWithoutCommas = numericThread.replace(/,/g, ''); 
const threadnumericValue = parseFloat(stringThreadWithoutCommas); 


              submitPromptChange(user_prompt , maxnumericValue , threadnumericValue);

          
        }}
      ></textarea>
    </div>

    <button className="genBtn"
  
      onClick={async () => {
        if(promptData.user_prompt[1] === "" || promptData.user_prompt[1] === undefined ){
          toast.error("Please write Prompt");
        }
      else  if (state !== "running" ) {
          await setCurrentPrompt({
            user_prompt: promptData?.user_prompt[1],
            type: '2'
          });
          setLoadingData((prev) => ({
            ...prev,
            loading2: true,
          }));
          setState("running");
        }
      }}
    >
   
        <span> GENERATE</span>
     
    </button>
  </div>

  {/* third block */}
  <div className="genBlock">
    <div className="genInputB">
      <textarea
        type="text"
        // value={promptData?.user_prompt[2] || ''}
        value={promptData?.user_prompt && promptData.user_prompt[2] ? promptData.user_prompt[2] : ''}

        placeholder="Write Prompt Here"
        onChange={(e) =>
          {
          setPromptData((prev) => ({
            ...prev,
            user_prompt: [
              prev?.user_prompt[0] || '',
              prev?.user_prompt[1] || '',
              e.target.value,
            ],
          }))
          let user_prompt = [
            promptData?.user_prompt[0] || '',
            promptData?.user_prompt[1] || '',
            e.target.value,
          ];

          let max_char = promptData?.max_char;
          let thread_size = promptData?.thread_size;

                       // for max char
const numericmax = max_char.toString(); 
const stringmaxWithoutCommas = numericmax.replace(/,/g, ''); 
const maxnumericValue = parseFloat(stringmaxWithoutCommas); 


// for thread
const numericThread = thread_size.toString(); 
const stringThreadWithoutCommas = numericThread.replace(/,/g, ''); 
const threadnumericValue = parseFloat(stringThreadWithoutCommas); 


          submitPromptChange(user_prompt ,maxnumericValue , threadnumericValue);

          
        }
        }
      ></textarea>
    </div>

    <button
    
      onClick={async () => {
        if(promptData.user_prompt[2] === "" || promptData.user_prompt[2] === undefined ){
          toast.error("Please write Prompt");
        }
      else if (state !== "running" ) {
          await setCurrentPrompt({
            user_prompt: promptData?.user_prompt[2],
            type: '3'
          });
          // await submitPromptChange();
          setLoadingData((prev) => ({
            ...prev,
            loading3: true,
          }));
          setState("running");
        }
      }}

      className="genBtn"
    >
    
        <span> GENERATE</span>
    
    </button>
  </div>


  {/* fourth block */}
  <div className="genBlock2">
    <p className="bloc2tit">Max Characters per tweet:</p>
    <div className="bloc2box">
      <NumericFormat
        allowNegative={false}
        name="max_char"
        thousandSeparator=","
        placeholder="280"
        value={promptData?.max_char}
        onChange={(e) =>
          {

            let max_char = e.target.value;
            
      // for max char
const numericmax = max_char.toString(); 
const stringmaxWithoutCommas = numericmax.replace(/,/g, ''); 
const maxnumericValue = parseFloat(stringmaxWithoutCommas); 


if(maxnumericValue >280){
   toast.error("maximum charecters are limited to 280")
}

          setPromptData((prev) => ({
            ...prev,
            max_char:maxnumericValue,
          }));

          let user_prompt = [
            promptData?.user_prompt[0],
            promptData?.user_prompt[1],
            promptData?.user_prompt[2],
          ];


          let thread_size = promptData?.thread_size;
          
              // for thread
const numericThread = thread_size.toString(); 
const stringThreadWithoutCommas = numericThread.replace(/,/g, ''); 
const threadnumericValue = parseFloat(stringThreadWithoutCommas); 
          
if (!Number.isNaN(maxnumericValue) && !Number.isNaN(threadnumericValue)) {
submitPromptChange(user_prompt ,maxnumericValue ,threadnumericValue);
}

        }

      }
      />
    </div>
  </div>

  {/* fivth block */}
  <div className="genBlock2">
    <p className="bloc2tit">Thread size (no of tweets):</p>
    <div className="bloc2box">
      <NumericFormat
        allowNegative={false}
        name="thread_size"
        thousandSeparator=","
        placeholder="1"
        value={promptData?.thread_size}

        onChange={(e) =>{

          let thread_size = e.target.value;

          
// for thread
const numericThread = thread_size.toString(); 
const stringThreadWithoutCommas = numericThread.replace(/,/g, ''); 
const threadnumericValue = parseFloat(stringThreadWithoutCommas); 

if(threadnumericValue > 10){
  toast.error("max thread size is limited to 10")
}

      
          setPromptData((prev) => ({
            ...prev,
            thread_size: threadnumericValue,
          }));

          let user_prompt = [
            promptData?.user_prompt[0],
            promptData?.user_prompt[1],
            promptData?.user_prompt[2],
          ];

          let max_char = promptData?.max_char;

                       // for max char
const numericmax = max_char.toString(); 
const stringmaxWithoutCommas = numericmax.replace(/,/g, ''); 
const maxnumericValue = parseFloat(stringmaxWithoutCommas); 


if (!Number.isNaN(maxnumericValue) && !Number.isNaN(threadnumericValue)) {
submitPromptChange(user_prompt ,maxnumericValue ,threadnumericValue);
}


        }
      }
      />
    </div>
  </div>

</div>
 }


      </div>
    </div>
  );
}

export default GeneratePage;



