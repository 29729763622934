import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from 'crypto-js';



const covertBooleanDecrpte3d = (value)=>{

  const decryptedData = CryptoJS.AES.decrypt(value, 'secretKey').toString(CryptoJS.enc.Utf8);

  
  const decryptedBoolean = decryptedData === 'true';
   return decryptedBoolean;
}

const textdecrpted = (value)=>{
  const decryptedData = CryptoJS.AES.decrypt(value, 'secretKey').toString(CryptoJS.enc.Utf8);
  return decryptedData;
}


const initialState = {
  refreshToken: localStorage.getItem("autoAiRefreshToken")
    ? textdecrpted(JSON.parse(localStorage.getItem("autoAiRefreshToken")))
    : null,

  accessToken: localStorage.getItem("autoAiaccessToken")
  ? textdecrpted(JSON.parse(localStorage.getItem("autoAiaccessToken")))
  : null,
  
  is_superUser: localStorage.getItem("autoAiis_superuser")
  ? covertBooleanDecrpte3d(JSON.parse(localStorage.getItem("autoAiis_superuser")))
  : null,
  is_staff:localStorage.getItem("autoAiis_staff")
  ? covertBooleanDecrpte3d(JSON.parse(localStorage.getItem("autoAiis_staff")))
  : null,

  is_approved:localStorage.getItem("autoAiis_approved")
  ? covertBooleanDecrpte3d(JSON.parse(localStorage.getItem("autoAiis_approved")))
  : null,

  is_twitter_authorized: localStorage.getItem("autoAiis_twitter_authorized")
  ? covertBooleanDecrpte3d(JSON.parse(localStorage.getItem("autoAiis_twitter_authorized")))
  : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setRefreshToken(state, value) {
      state.refreshToken = value.payload;
    },
    setAccessToken(state, value) {
      state.accessToken = value.payload;
    },
    setSuperUser(state, value) {
      state.is_superUser = value.payload;
    },
    setStaff(state, value) {
      state.is_staff = value.payload;
    },
    setIsApproved(state, value) {
      state.is_approved = value.payload;
    },
    setIsTwitterAuth(state, value) {
      state.is_twitter_authorized = value.payload;
    },
  
  },
});

export const {
  setRefreshToken,
  setAccessToken,
  setSuperUser,
  setStaff,
  setIsApproved,
  setIsTwitterAuth,
} = authSlice.actions;

export default authSlice.reducer;
